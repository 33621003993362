import React from "react";
import {
  makeBannerProps,
  StandardPageProps,
  makeStandardPageProps,
  makeHtml,
} from "../../utils/common";
import {
  assignFeefoReviewAPITag,
  checkTradingTabExpiry,
  OnMobileDevice,
} from "../../utils";
import BannerComponent from "../mapped-templates/BannerTemplate";
import { useEffect, useState } from "react";
import HeaderComponentTemplate from "../mapped-templates/HeaderTemplate";
import { BannerWidgetContainer } from "../mapped-templates/BannerWidgetContainerTemplate";
import PrimaryContent from "../../utils/PrimaryContent";
import PageProperties from "../PagePropertiesTemplate";
import PageHeader from "../../components/SemanticTheme/PageHeader";
import { Container, Hidden } from "@mui/material";
import { useRouter } from "next/router";
import FooterComponentTemplate from "../mapped-templates/FooterTemplate";
import ContentArea from "../../utils/ContentArea";
import {
  useIsAuthenticated,
  useIsAuthenticating,
  useLoginPageUrlSelector,
} from "@/store/selectors";
import { MobileTradingTab } from "@components/SemanticTheme/MobileTradingTab";
import { MappedTemplateExperimentType } from "src/types/Content";
import {
  BasicPageEntityResponseCollection,
  BasicPagePrimaryContentDynamicZone,
  FooterEntityResponseCollection,
  HeaderEntityResponseCollection,
  SeoStructuredDataEntityResponse,
} from "src/graphql/generated-strapi/types";
import { ParsedUrlQuery } from "querystring";
import { updateAbsoluteLink } from "@components/Utils";

interface BasicPageProps {
  rest: {
    strapiContent: {
      data: {
        basicPages: BasicPageEntityResponseCollection;
      };
    };
    headerContent: {
      data: { headers: HeaderEntityResponseCollection };
    };
    footerContent: {
      data: { footers: FooterEntityResponseCollection };
    };
    seoStructuredData: {
      data: {
        seoStructuredData: SeoStructuredDataEntityResponse;
      };
    };
    bslData?: any;
    experiments?: { [key in string]: string | boolean };
    hideSubscribeSticky?: boolean;
    queryData?: ParsedUrlQuery;
    headers: any;
  };
}

const BasicPage = (props: BasicPageProps & MappedTemplateExperimentType) => {
  const experiments = props?.rest?.experiments;
  const seoStructuredData =
    props?.rest?.seoStructuredData?.data?.seoStructuredData?.data?.attributes;
  const basicPageCmsData =
    props?.rest?.strapiContent?.data?.basicPages?.data?.[0]?.attributes;
  const isHomePage = basicPageCmsData?.PageRoute === "/";
  const bannerProps = makeBannerProps(basicPageCmsData || {});
  const tradingTabProps = basicPageCmsData?.TradingTab?.data?.attributes;
  const bannerComponentRef = basicPageCmsData?.BannerComponent;
  const isBookingWidget =
    bannerComponentRef && !!Object.keys(bannerComponentRef)?.length;
  const headerProps =
    props?.rest?.headerContent?.data?.headers?.data?.[0]?.attributes;
  const footerProps =
    props?.rest?.footerContent?.data?.footers?.data?.[0]?.attributes;
  const pageImgQuality = basicPageCmsData?.ImageQuality;
  const primaryContent = basicPageCmsData?.PrimaryContent;
  const stickyBannerProps =
    basicPageCmsData?.StickyBanner?.data?.attributes || null;
  const pageProperties: StandardPageProps = makeStandardPageProps(
    basicPageCmsData?.seo || {},
  );
  const faqComponentContent = basicPageCmsData?.PrimaryContent?.filter((item) =>
    item?.hasOwnProperty("faq_comp"),
  );
  pageProperties["faqComponentContent"] = faqComponentContent;
  const bslData = props?.rest?.bslData ?? null;
  assignFeefoReviewAPITag(pageProperties, basicPageCmsData);
  pageProperties["ShowSubscribeModal"] = basicPageCmsData?.ShowSubscribeModal;

  if (stickyBannerProps && headerProps)
    headerProps["stickyBannerProps"] = stickyBannerProps;
  if (pageImgQuality && headerProps)
    headerProps["pageImgQuality"] = pageImgQuality;
  if (props?.rest?.headers) pageProperties["nextHeaders"] = props.rest.headers;
  if (!!seoStructuredData)
    pageProperties["seoStructuredData"] = seoStructuredData;

  const router = useRouter();
  const isAuthenticated = useIsAuthenticated();
  const isAuthenticating = useIsAuthenticating();
  const loginUrl = useLoginPageUrlSelector();
  const parsedText = (text?: string) => {
    return text ? (
      <div dangerouslySetInnerHTML={makeHtml(text as string)} />
    ) : undefined;
  };
  const [expPrimaryContent, setExpPrimaryContent] =
    useState<BasicPagePrimaryContentDynamicZone[]>(primaryContent);
  const [inspireMePrimaryContent, setInspireMePrimaryContent] =
    useState<BasicPagePrimaryContentDynamicZone[]>(primaryContent);

  const showPaymentMethods = experiments?.showPaymentMethods;
  const moveInspireMe = experiments?.moveFeaturedInAboveInspireMe;

  //For CRO:FR-4
  const reOrderLastMinuteBreak = experiments?.reOrderLastMinuteBreak;

  useEffect(() => {
    if (reOrderLastMinuteBreak) {
      const _primaryContent = !!!showPaymentMethods
        ? expPrimaryContent
        : moveInspireMe
          ? inspireMePrimaryContent
          : primaryContent;

      if (
        _primaryContent[3]?.["richCards"]?.data[0]?.attributes?.Title ===
        "Last minute breaks "
      ) {
        [_primaryContent[2], _primaryContent[3]] = [
          _primaryContent[3],
          _primaryContent[2],
        ];
        setExpPrimaryContent(_primaryContent);
        setInspireMePrimaryContent(_primaryContent);
      }
    }
  }, []);
  //

  //For CRO:FR-526
  const rearrangeContent = (
    primaryContent: BasicPagePrimaryContentDynamicZone[],
  ): BasicPagePrimaryContentDynamicZone[] => {
    const targetIndex = primaryContent.findIndex((element) =>
      element?.["textAreas"]?.data.some(
        (textArea) =>
          textArea.attributes.Name === "Homepage-As seen in - rich text slim",
      ),
    );

    const referenceIndex = primaryContent.findIndex((element) =>
      element?.["richCards"]?.data.some(
        (card) => card.attributes.Title === "Inspire me...",
      ),
    );

    if (targetIndex !== -1 && referenceIndex !== -1) {
      const [targetElement] = primaryContent.splice(targetIndex, 1);

      primaryContent.splice(referenceIndex, 0, targetElement);
    }

    return primaryContent;
  };

  useEffect(() => {
    if (!!moveInspireMe) {
      setInspireMePrimaryContent(
        rearrangeContent(
          showPaymentMethods ? primaryContent : expPrimaryContent,
        ),
      );
    }
  }, []);
  //

  //For CRO:FR-494
  useEffect(() => {
    const currPrimaryContent = moveInspireMe
      ? inspireMePrimaryContent
      : primaryContent;
    const ExpPrimaryContenWithOutRichTextForPaymentMethods =
      currPrimaryContent.filter((component) => {
        const isUnwantedRichTextArea: BasicPagePrimaryContentDynamicZone[] =
          component["textAreas"]?.data.some(
            (textArea) =>
              textArea.attributes.Name ===
                "Pay-monthly-Payment options intro" ||
              textArea.attributes.Name === "Core-spacer - rich text",
          );
        return !isUnwantedRichTextArea;
      });
    const ExpPrimaryContentWithoutPaymentMethods =
      ExpPrimaryContenWithOutRichTextForPaymentMethods &&
      ExpPrimaryContenWithOutRichTextForPaymentMethods.filter((component) => {
        const isUnwantedDescriptionCard: BasicPagePrimaryContentDynamicZone[] =
          component["descriptionCard"]?.data.some(
            (descriptionCard) =>
              descriptionCard.attributes.Name ===
              "Pay-monthly-description card container - 3 ways to pay",
          );
        return !isUnwantedDescriptionCard;
      });
    setExpPrimaryContent(ExpPrimaryContentWithoutPaymentMethods);
  }, []);
  //

  useEffect(() => {
    if (!isAuthenticated && !isAuthenticating) {
      const pathname = router.asPath;
      if (basicPageCmsData?.AccountAuthenticated)
        !!loginUrl && router.push(`${loginUrl}?returnUrl=${pathname}`);
      return;
    }
  }, [isAuthenticated, isAuthenticating, loginUrl]);

  let nextHeaders: any;
  if (!!props?.rest?.headers) {
    nextHeaders = JSON.parse(props?.rest?.headers);
  }
  const isMobileDevice = OnMobileDevice(nextHeaders);

  const bannerImgURL = isMobileDevice
    ? bannerProps?.mobileBannerImage || bannerProps?.bannerImage
    : bannerProps?.bannerImage;

  const mobileYellowBannerDeskandTab =
    !!experiments?.mobileYellowBannerDeskandTab;
  const moveSearchAndBannerBelowHero =
    !!experiments?.moveSearchAndBannerBelowHero;

  return (
    <>
      <PageProperties {...pageProperties} />
      {bannerProps && (
        <BannerComponent
          isBookingWidget={isBookingWidget}
          queryData={props?.rest?.queryData}
          bannerImageURL={bannerImgURL}
          additionalProps={experiments}
          {...bannerProps}
        >
          {headerProps && (
            <HeaderComponentTemplate
              content={headerProps}
              additionalProps={experiments}
              hideSubscribeSticky={props?.rest?.hideSubscribeSticky}
            />
          )}
          <Hidden mdDown={moveSearchAndBannerBelowHero}>
            {tradingTabProps?.TradingTabInMobile &&
              checkTradingTabExpiry(bannerProps) && (
                <MobileTradingTab
                  tradingTab={{
                    url: updateAbsoluteLink(
                      bannerProps?.tradingTabURL?.data?.attributes?.PageRoute,
                    ),
                    tradingTabInMobile: parsedText(
                      tradingTabProps?.TradingTabInMobile,
                    ),
                  }}
                  isBookingWidget={isBookingWidget}
                  mobileYellowBannerDeskandTab={mobileYellowBannerDeskandTab}
                />
              )}
            {bannerComponentRef && isBookingWidget && (
              <BannerWidgetContainer
                mobileYellowBannerDeskandTab={mobileYellowBannerDeskandTab}
              >
                <ContentArea
                  content={bannerComponentRef}
                  pageImgQuality={pageImgQuality}
                  additionalProps={experiments}
                />
              </BannerWidgetContainer>
            )}
          </Hidden>
        </BannerComponent>
      )}
      {moveSearchAndBannerBelowHero && (
        <Hidden mdUp>
          {tradingTabProps?.TradingTabInMobile &&
            checkTradingTabExpiry(bannerProps) && (
              <MobileTradingTab
                tradingTab={{
                  url: updateAbsoluteLink(
                    bannerProps?.tradingTabURL?.data?.attributes?.PageRoute,
                  ),
                  tradingTabInMobile: parsedText(
                    tradingTabProps?.TradingTabInMobile,
                  ),
                }}
                isBookingWidget={isBookingWidget}
                mobileYellowBannerDeskandTab={mobileYellowBannerDeskandTab}
                moveSearchAndBannerBelowHero={
                  !!experiments?.moveSearchAndBannerBelowHero
                }
              />
            )}
          {bannerComponentRef && isBookingWidget && (
            <BannerWidgetContainer
              mobileYellowBannerDeskandTab={mobileYellowBannerDeskandTab}
            >
              <ContentArea
                content={bannerComponentRef}
                pageImgQuality={pageImgQuality}
                additionalProps={experiments}
              />
            </BannerWidgetContainer>
          )}
        </Hidden>
      )}
      {!!basicPageCmsData?.Title ? (
        <Container>
          <PageHeader
            title={basicPageCmsData?.Title || ""}
            subtitle={basicPageCmsData?.PageDescription || ""}
          />
          {primaryContent && (
            <PrimaryContent
              primaryContent={
                !!!showPaymentMethods
                  ? expPrimaryContent
                  : moveInspireMe
                    ? inspireMePrimaryContent
                    : primaryContent
              }
              bslData={bslData}
              pageImgQuality={pageImgQuality}
              additionalProps={experiments}
            />
          )}
        </Container>
      ) : (
        primaryContent && (
          <PrimaryContent
            primaryContent={
              !!!showPaymentMethods
                ? expPrimaryContent
                : moveInspireMe
                  ? inspireMePrimaryContent
                  : primaryContent
            }
            bslData={bslData}
            pageImgQuality={pageImgQuality}
            additionalProps={experiments}
            isHomePage={isHomePage}
          />
        )
      )}

      {footerProps && (
        <FooterComponentTemplate
          content={{
            ...footerProps,
            experiments,
          }}
        />
      )}
    </>
  );
};

export default BasicPage;
